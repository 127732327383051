import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { TileModel } from '../../../core-lib/models/tile.model';
import { getSessionIsLoggedIn } from '../../../session/reducers/session.reducer';
import {
  FavoriteActionDelete,
  FavoriteActionGet,
  FavoriteActionGetFirst,
  FavoriteActionSaveOrder,
} from '../../ngrx/actions/favorite.actions';
import {
  TemplateActionDelete,
  TemplateActionGet,
  TemplateActionSaveOrder,
  TemplateActionSetCollapsed,
} from '../../ngrx/actions/template.action';
import { ConfigState } from '../../ngrx/reducers/config.reducer';
import { FavoriteState, getFavoriteArrayState } from '../../ngrx/reducers/core-favorite.store';
import { getTemplateCollapsedState, getTemplatesArrayState, TemplateState } from '../../ngrx/reducers/core-template-store';
import { getConfigSystemMessage, getConfigTopCategoriesState, isReadonly, isRestricted } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-index',
  templateUrl: './route-index.component.html',
  styleUrls: ['./route-index.component.scss'],
})
export class RouteIndexComponent implements OnInit, OnDestroy {

  isRestricted = this.configStore$.select(isRestricted);
  isReadonly = this.configStore$.select(isReadonly);

  systemMessage$ = this.configStore$.select(getConfigSystemMessage);

  categoryCards$ = this.configStore$.select(getConfigTopCategoriesState).pipe(
    map((categories): TileModel[] => {
      return categories.map((c) => ({
        data: {
          caption: c.displayName,
          class: c.name,
          backgroundColor: c.backgroundColor,
          color: c.color,
          icon: c.iconName,
          order: c.viewOrder,
          routerLink: `/forms/${c.name}`,
        },
      }));
    }),
  );

  favoriteCards$ = this.favoriteStore$.select(getFavoriteArrayState).pipe(
    map((favorites): TileModel[] => {
      return favorites.map((c) => ({
        data: {
          caption: c.name,
          subCaption: c.nodeName,
          class: c.category.name,
          backgroundColor: c.category.backgroundColor,
          color: c.category.color,
          icon: c.category.iconName,
          identifier: c.identifier,
          order: c.viewOrder,
          routerLink: `/forms/${c.category.name}/${c.identifier}/new`,
        },
      }));
    }),
  );

  initialTemplateView$ = this.templateStore$.select(getTemplateCollapsedState).pipe(
    map(value => value ? 4 : undefined),
  );

  templateCards$ = this.templateStore$.select(getTemplatesArrayState).pipe(
    map((templates): TileModel[] => {
      return templates.map((c) => ({
        data: {
          caption: c.templateName,
          subCaption: '',
          class: c.form.category.name,
          backgroundColor: c.form.category.backgroundColor,
          color: c.form.category.color,
          identifier: c.form.identifier,
          templateName: c.templateName,
          order: c.viewOrder,
          proposalId: c.id,
          routerLink: `/forms/${c.form.category.name}/${c.form.identifier}/template/${c.templateName}`,
        },
      }));
    }),
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private configStore$: Store<ConfigState>,
    private favoriteStore$: Store<FavoriteState>,
    private templateStore$: Store<TemplateState>,
    private dialog: MatDialog,
  ) {
  }

  private componentDestroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }


  ngOnInit(): void {
    // ToDo: durch Effekt ersetzen
    this.configStore$.select(getSessionIsLoggedIn).pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(() => {
      this.favoriteStore$.dispatch(new FavoriteActionGetFirst());
      this.templateStore$.dispatch(new TemplateActionGet());
    });
  }

  removeFavorite(identifier): void {
    this.favoriteStore$.dispatch(new FavoriteActionDelete(identifier));
  }

  saveFavoriteOrder(data): void {
    this.favoriteStore$.dispatch(new FavoriteActionSaveOrder(data.map(item => item.data)));
  }

  removeTemplate({ identifier, templateName }): void {
    this.openPrompt(identifier, templateName);
  }

  saveTemplateOrder(data): void {
    this.templateStore$.dispatch(new TemplateActionSaveOrder(data.map(item => item.data)));
  }

  showAllTemplates(): void {
    this.templateStore$.dispatch(new TemplateActionSetCollapsed(false));
  }

  showAllFavorites(): void {
    this.favoriteStore$.dispatch(new FavoriteActionGet());
  }

  openPrompt(identifier, templateName) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'deleteTemplateTitle',
        text: 'deleteTemplateText',
      },
    });

    dialogRef.afterClosed().pipe(
      filter(event => event === 'accepted'),
    ).subscribe(() => this.templateStore$.dispatch(new TemplateActionDelete(identifier, templateName)));
  }

}
