import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemStaticConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.staticConfiguration;
  static TYPENAME = 'typeStatic';
  '@class' = ConfigItemStaticConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemStaticConfigurationDtoModel.TYPENAME;
  value: string = undefined;
}
