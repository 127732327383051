import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { filter, takeUntil } from 'rxjs/operators';
import {
  QueryPromptMessageActionsComponent
} from '../../../core-lib/components/query-prompt-message-actions/query-prompt-message-actions.component';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import { getProposalStates, ProposalHeadModel, ProposalState } from '../../../core-lib/models/proposal-head.model';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { QueryPromptMessageActionsModel } from '../../../core-lib/models/query-prompt-message-actions.model';
import { I18nPipe } from '../../../core-lib/pipes/i18n.pipe';
import { TableColumnDefModel } from '../../models/table-column-def.model';
import { proposalTableActions } from '../../ngrx/actions/core-overview-table.actions';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';
import { OverviewTableBase } from '../../utils/overview-table-base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-proposal-table',
  templateUrl: './proposal-table.component.html',
  styleUrls: ['./proposal-table.component.scss'],
  providers: [CurrencyPipe],
})
export class ProposalTableComponent extends OverviewTableBase<ProposalHeadModel> implements OnInit, AfterViewInit {

  selected: ProposalHeadModel[] = [];

  private readonly neededFormKeys = [
    'W2',
    'W4',
    'W6',
    'W8',
    'W10',
    'W12',
    'W16',
    'W18',
    'W20',
    'W22',
    'W24',
    'W26',
    'W28',
    'W30',
    'W32',
    'W34',
  ];
  private readonly disableSendAndConfirmFormKeys = ['K2', 'A4'];

  private readonly neededState = [
    ProposalState.APPROVED,
    ProposalState.FINISHED,
    ProposalState.SAP_ERROR,
    ProposalState.WAITING_FOR_SIGNATURE,
    ProposalState.SEND_FAILED,
  ];

  columnMode = ColumnMode.force;

  @ViewChild('filterTemplate', { static: true })
  filterTemplate: TemplateRef<ElementRef>;

  @ViewChild('numberFilterTemplate', { static: true })
  numberFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('authorFilterTemplate', { static: true })
  authorFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('arrangerFilterTemplate', { static: true })
  arrangerFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('stateFilterTemplate', { static: true })
  stateFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('categoryCellTemplate', { static: true })
  categoryCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('dateCellTemplate', { static: true })
  dateCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('dateFilterTemplate', { static: true })
  dateFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('i18nTextCellTemplate', { static: true })
  i18nTextCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('amountCellTemplate', { static: true })
  amountCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('approvalActionsCellTemplate', { static: true })
  approvalActionsCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('notApprovalActionsCellTemplate', { static: true })
  notApprovalActionsCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('checkboxCell', { static: true })
  checkboxCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('checkboxHeaderTemplate', { static: true })
  checkboxHeaderTemplate: TemplateRef<ElementRef>;

  states = getProposalStates();

  selectionType = SelectionType.checkbox;

  constructor(
    protected store$: Store<CoreFeatureState>,
    protected dialog: MatDialog,
    protected i18nPipe: I18nPipe,
    private currencyPipe: CurrencyPipe,
  ) {
    super(
      store$,
      dialog,
      i18nPipe,
      proposalTableActions,
      'proposals',
    );

    this.initSortFilterPageConfig = {
      filter: {
        authorId: '',
        form: '',
        id: '',
        state: '',
        cateringStartFrom: undefined,
        createdAtFrom: undefined,
        createdAtUntil: undefined,
        category: '',
        amount: '',
        lastModified: undefined,
        currentArrangerId: '',
        useCase: '',
        recipientName: '',
        sapReferenceNumber: '',
      },
      page: {
        number: 0,
        size: 20,
      },
      sort: {
        prop: 'id',
        dir: 'desc',
      },
      selection: undefined,
    };
  }

  createTemplates = (): { [key: string]: TableColumnDefModel<any> } => ({
    ...(this.type.toLowerCase().includes('approval') && !this.type.toLowerCase().includes('finished') ? {
      selected: {
        columnName: 'select',
        prop: 'selected',
        sortable: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: false,
        cellTemplate: this.checkboxCellTemplate,
        headerTemplate: this.checkboxHeaderTemplate,
        maxWidth: 1,
        alwaysVisible: true,
      },
    } : {}),
    actions: {
      columnName: 'actions',
      cellTemplate: this.type.toLowerCase().includes('approval') ? this.approvalActionsCellTemplate : this.notApprovalActionsCellTemplate,
      prop: 'publicId',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    id: {
      columnName: 'id',
      summaryTemplate: this.filterTemplate,
      prop: 'publicId',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    authorId: {
      columnName: 'authorId',
      summaryTemplate: this.authorFilterTemplate,
      prop: 'author',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    state: {
      columnName: 'state',
      summaryTemplate: this.stateFilterTemplate,
      cellTemplate: this.i18nTextCellTemplate,
      prop: 'state',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    startDateTime: {
      columnName: 'cateringStart',
      summaryTemplate: undefined,
      cellTemplate: this.dateCellTemplate,
      prop: 'startDateTime',
      sortable: false,
      draggable: true,
      resizable: true,
    },
    created: {
      columnName: 'created',
      summaryTemplate: this.dateFilterTemplate,
      cellTemplate: this.dateCellTemplate,
      prop: 'createdAt',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    category: {
      columnName: 'category',
      summaryTemplate: this.filterTemplate,
      cellTemplate: this.categoryCellTemplate,
      prop: ['categoryName', 'formName'],
      sortable: true,
      draggable: true,
      resizable: true,
    },
    amount: {
      columnName: 'amount',
      summaryTemplate: this.numberFilterTemplate,
      cellTemplate: this.amountCellTemplate,
      prop: 'amount',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    lastModified: {
      columnName: 'lastModified',
      summaryTemplate: this.dateFilterTemplate,
      cellTemplate: this.dateCellTemplate,
      prop: 'lastModified',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    currentArrangerId: {
      columnName: 'currentArrangerId',
      summaryTemplate: this.arrangerFilterTemplate,
      prop: 'currentArrangerName',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    useCase: {
      columnName: 'useCase',
      summaryTemplate: this.filterTemplate,
      prop: 'useCase',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    recipientName: {
      columnName: 'recipientName',
      summaryTemplate: this.filterTemplate,
      prop: 'recipientName',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    sapReferenceNumber: {
      columnName: 'sapReferenceNumber',
      summaryTemplate: this.filterTemplate,
      prop: 'bookingNumber',
      sortable: true,
      draggable: true,
      resizable: true,
    },
  });

  ngAfterViewInit(): void {
    this.templates$.next(this.createTemplates());
  }

  showAmount(row: ProposalHeadModel, prop: string) {
    if (row.categoryName === 'catering') {
      return '';
    } else {
      return row[prop];
    }
  }

  getFormIdentifier(identifier: string, state: ProposalState) {
    if (this.neededFormKeys.includes(identifier) && this.neededState.includes(state)) {
      return identifier + 'wfs';
    } else {
      return identifier;
    }
  }

  shouldDisableSendAndConfirm(identifier: string, proposal: ProposalHeadModel, isAdmin: boolean, isReadOnly: boolean) {
    return this.disableSendAndConfirmFormKeys.includes(identifier)
      || (proposal.duplicate && proposal.state === 'IN_INSPECT')
      || (!proposal.companyActive && !isAdmin)
      || isReadOnly;
  }

  onSelect({ selected }: { selected: ProposalHeadModel[] }) {
    this.selected = (selected || []).filter(s => s.publicId);
  }

  isSelectableFn() {
    return (event: ProposalHeadModel) => {
      return !this.shouldDisableSendAndConfirm(event.form.identifier, event, false, false);
    };
  }

  openDialogMultipleApprove() {
    const realSelected = this.selected.filter(s => !this.shouldDisableSendAndConfirm(s.form.identifier, s, false, false));
    const sum = realSelected.reduce((acc, cur) => acc + cur.amount, 0);
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: {
        title: 'approveProcess',
        text: 'approveProcessMultipleMessage',
        replacements: {
          amount: this.currencyPipe.transform(sum, 'EUR', 'symbol', '1.2-2', 'de'),
        },
        declineLabel: undefined,
        acceptLabel: undefined,
      } as QueryPromptData,
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => {
      realSelected.forEach(s => this.approveMultiple(s.publicId));
      this.selected = [];
    });
  }

  openDialogMultipleReject() {
    const realSelected = this.selected.filter(s => !this.shouldDisableSendAndConfirm(s.form.identifier, s, false, false));
    const sum = realSelected.reduce((acc, cur) => acc + cur.amount, 0);
    const dialogRef = this.dialog.open(QueryPromptMessageActionsComponent, {
      data: {
        title: 'rejectProcess',
        text: 'rejectProcessMultipleMessage',
        message: this.message,
        info: this.info,
        textarea: true,
        replacements: {
          amount: this.currencyPipe.transform(sum, 'EUR', 'symbol', '1.2-2', 'de'),
        },
        declineLabel: undefined,
        acceptLabel: undefined,
      } as QueryPromptMessageActionsModel,
    });

    dialogRef.afterClosed().pipe(
      filter(result => result.event === 'accepted'),
    ).subscribe(result => {
      this.message = result.message;
      if (!this.message) {
        this.info = 'infoApproveNoRejectMessage';
        this.openDialogMultipleReject();
      } else {
        realSelected.forEach(s => this.rejectMultiple(s.publicId));
        this.selected = [];
        this.message = '';
      }
    });
  }

  isArray(variable: unknown) {
    return Array.isArray(variable);
  }

  // Funktioniert nicht für Arrays
  access(obj: object, path: string) {
    return path.split('.').reduce((prev, cur) => prev[cur], obj);
  }

}
