import { ConfigItemAccountingConfigurationDtoModel } from './config-item-accounting-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from './config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from './config-item-date-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from './config-item-daterange-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from './config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from './config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel, ConfigItemListItemConfigurationDtoModel } from './config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from './config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from './config-item-text-input-configuration-dto.model';

export class BackendClassesModel {
  readonly dateConfiguration = 'de.enviam.easy.backend.core.configuration.model.DateConfiguration';
  readonly listConfiguration = 'de.enviam.easy.backend.core.configuration.model.ListConfiguration';
  readonly numberConfiguration = 'de.enviam.easy.backend.core.configuration.model.NumberConfiguration';
  readonly listItemConfiguration = 'de.enviam.easy.backend.core.configuration.model.ListItemConfiguration';
  readonly arrangerlistItemConfiguration = 'de.enviam.easy.backend.core.configuration.model.ArrangerListConfiguration';
  readonly textInputConfiguration = 'de.enviam.easy.backend.core.configuration.model.TextInputConfiguration';
  readonly textareaInputConfiguration = 'de.enviam.easy.backend.core.configuration.model.TextareaInputConfiguration';
  readonly checkboxConfiguration = 'de.enviam.easy.backend.core.configuration.model.CheckboxConfiguration';
  readonly staticConfiguration = 'de.enviam.easy.backend.core.configuration.model.StaticConfiguration';
  readonly daterangeConfiguration = 'de.enviam.easy.backend.core.configuration.model.DaterangeConfiguration';
  readonly fileUploadConfiguration = 'de.enviam.easy.backend.core.configuration.model.FileUploadConfiguration';
  readonly arrangerListConfiguration = 'de.enviam.easy.backend.core.configuration.model.ArrangerListConfiguration';
  readonly arrangerConfiguration = 'de.enviam.easy.backend.core.configuration.model.ArrangerConfiguration';
  readonly accountingConfiguration = 'de.enviam.easy.backend.core.configuration.model.AccountingConfiguration';
  readonly ledgerAccountConfiguration = 'de.enviam.easy.backend.core.configuration.model.LedgerAccountConfiguration';

  static cast(uncastObj: ConfigItemConfigurationDtoModel) {
    switch (uncastObj['@class']) {
      case backendClasses.textInputConfiguration:
        return <ConfigItemTextInputConfigurationDtoModel>uncastObj;
      case backendClasses.listItemConfiguration:
        return <ConfigItemListItemConfigurationDtoModel>uncastObj;
      case backendClasses.listConfiguration:
        return <ConfigItemListConfigurationDtoModel>uncastObj;
      case backendClasses.checkboxConfiguration:
        return <ConfigItemCheckboxConfigurationDtoModel>uncastObj;
      case backendClasses.dateConfiguration:
        return <ConfigItemDateConfigurationDtoModel>uncastObj;
      case backendClasses.numberConfiguration:
        return <ConfigItemNumberConfigurationDtoModel>uncastObj;
      case backendClasses.fileUploadConfiguration:
        return <ConfigItemFileUploadConfigurationDtoModel>uncastObj;
      case backendClasses.daterangeConfiguration:
        return <ConfigItemDaterangeConfigurationDtoModel>uncastObj;
      case backendClasses.arrangerListConfiguration:
        return <ConfigItemNumberConfigurationDtoModel>uncastObj;
      case backendClasses.arrangerConfiguration:
        return <ConfigItemNumberConfigurationDtoModel>uncastObj;
      case backendClasses.accountingConfiguration:
        return <ConfigItemAccountingConfigurationDtoModel>uncastObj;
      case backendClasses.ledgerAccountConfiguration:
        return <ConfigItemLedgerAccountConfigurationDtoModel>uncastObj;
    }
  }
}

export const backendClasses = new BackendClassesModel();
