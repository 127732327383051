import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TileModel } from '../../models/tile.model';
import { TilesTemplateDirective } from './tiles-template.directive';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-tiles-container',
  templateUrl: './tiles-container.component.html',
  styleUrls: ['./tiles-container.component.scss'],
})
export class TilesContainerComponent implements AfterContentInit {
  @Input() heading: string;
  @Input() disableDrag = false;
  @Input() disabled = false;

  @ViewChild('addCardTemp', { static: true })
  private addCardTpl: TemplateRef<any>;

  @Input() set tiles(value: TileModel[]) {
    this._tiles = value;
    if (this.addCard) {
      const newTile: TileModel = {
        template: this.addCardTpl,
        data: {
          color: 'white',
          backgroundColor: 'gray',
        },
      };
      this._tiles.push(newTile);
    }
  }

  get tiles(): TileModel[] {
    const sortedTiles = this._tiles.sort((a, b) => a.data.order - b.data.order).map(t => ({
      ...t,
      data: {
        ...t.data,
        class: this.disabled ? 'disabled' : t.data.class,
        backgroundColor: this.disabled ? '#C9C9C9' : t.data.backgroundColor,
        routerLink: this.disabled ? '' : t.data.routerLink,
      }
    }));
    return this.collapsedDisplayCount >= 0 && !this.showAll ? sortedTiles.slice(0, this.collapsedDisplayCount) : sortedTiles;
  }

  _tiles: TileModel[] = [];

  @Input() maxCols = 12;
  @Input() closeButton = false;
  @Input() addCard = false;
  @Input() collapsedDisplayCount: number;
  @Output() addCardClicked = new EventEmitter<void>();
  @Output() cardClicked = new EventEmitter<{ $event: any, data: TileModel }>();
  @Output() closeButtonClicked = new EventEmitter<any>();
  @Output() saveViewButtonClicked = new EventEmitter<any>();

  // use content children to find projected templates
  @ContentChildren(TilesTemplateDirective)
  gridTemplates: QueryList<TilesTemplateDirective>;

  gridTemplateMap: { [key: string]: TemplateRef<any> } = {};

  showAll = false;

  showSaveButton = false;

  ngAfterContentInit() {
    // store queried templates in map for use in template
    this.gridTemplates.forEach(t => this.gridTemplateMap[t.key] = t.template);
  }

  anyCardClicked(data, $event) {
    this.cardClicked.emit({
      data,
      $event,
    });
    if (typeof data.onClick === 'function') {
      data.onClick($event);
    }
  }

  anyCloseButtonClicked(data, $event) {
    $event.stopPropagation();
    this.closeButtonClicked.emit(data);
  }

  anyAddCardClicked() {
    this.addCardClicked.emit();
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  showMoreButton() {
    return this.collapsedDisplayCount >= 0 && this._tiles.length > this.collapsedDisplayCount;
  }

  saveView(event) {
    this.saveViewButtonClicked.emit(event);
    this.showSaveButton = false;
  }

  dropped(event: CdkDragDrop<{ item: TileModel; index: number }>) {
    moveItemInArray(this._tiles, event.previousContainer.data.index, event.container.data.index);

    for (let i = 0; i < this._tiles.length; i++) {
      this._tiles[i].data.order = i;
    }

    this.showSaveButton = true;
  }


}
