import { Routes } from '@angular/router';
import { DataProtectionComponent } from '../../core-lib/components/data-protection/data-protection.component';
import { FaqComponent } from '../../core-lib/components/faq/faq.component';
import { LegalDisclosureComponent } from '../../core-lib/components/legal-disclosure/legal-disclosure.component';
import { RouteAccountDataComponent } from '../components/route-account-data/route-account-data.component';
import { RouteApprovalOverviewComponent } from '../components/route-approval-overview/route-approval-overview.component';
import { RouteCateringOverviewComponent } from '../components/route-catering-overview/route-catering-overview.component';
import { RouteConfigArticleListComponent } from '../components/route-config-article-list/route-config-article-list.component';
import { RouteConfigCategoryInfoComponent } from '../components/route-config-category-info/route-config-category-info.component';
import {
  RouteConfigCateringOrderDataComponent
} from '../components/route-config-catering-order-data/route-config-catering-order-data.component';
import { RouteConfigCompaniesComponent } from '../components/route-config-companies/route-config-companies.component';
import { RouteConfigCompanyComponent } from '../components/route-config-company/route-config-company.component';
import { RouteConfigDashboardComponent } from '../components/route-config-dashboard/route-config-dashboard.component';
import { RouteConfigDeputiesComponent } from '../components/route-config-deputies/route-config-deputies.component';
import { RouteConfigFaqComponent } from '../components/route-config-faq/route-config-faq.component';
import { RouteConfigFormComponent } from '../components/route-config-form/route-config-form.component';
import { RouteConfigFormsAddComponent } from '../components/route-config-forms-add/route-config-forms-add.component';
import { RouteConfigFormsComponent } from '../components/route-config-forms/route-config-forms.component';
import {
  RouteConfigLedgerAccountListComponent
} from '../components/route-config-ledger-account-list/route-config-ledger-account-list.component';
import {
  RouteConfigLocationSelectionComponent
} from '../components/route-config-location-selection/route-config-location-selection.component';
import {
  RouteConfigMassDataProcessingComponent
} from '../components/route-config-mass-data-processing/route-config-mass-data-processing.component';
import { RouteConfigQueueComponent } from '../components/route-config-queue/route-config-queue.component';
import { RouteConfigRoomComponent } from '../components/route-config-room/route-config-room.component';
import { RouteConfigSalutationsComponent } from '../components/route-config-salutations/route-config-salutations.component';
import { RouteConfigServiceCenterComponent } from '../components/route-config-service-center/route-config-service-center.component';
import { RouteConfigSystemMessageComponent } from '../components/route-config-system-message/route-config-system-message.component';
import { RouteConfigTaxRatesComponent } from '../components/route-config-tax-rates/route-config-tax-rates.component';
import { RouteConfigComponent } from '../components/route-config/route-config.component';
import { RouteFormOverviewComponent } from '../components/route-form-overview/route-form-overview.component';
import { RouteFormsComponent } from '../components/route-forms/route-forms.component';
import { RouteIndexComponent } from '../components/route-index/route-index.component';
import { RouteOcrComponent } from '../components/route-ocr/route-ocr.component';
import { RouteTemplateDeputyComponent } from '../components/route-template-deputy/route-template-deputy.component';
import { AppConfigGuard } from '../guards/app-config.guard';
import { CateringRoleBasedRedirectGuard } from '../guards/catering-role-based-redirect.guard';
import { GeneralAdminGuard } from '../guards/general-admin.guard';
import { notRestricted } from '../guards/guards';
import { LoginGuard } from '../guards/login.guard';
import { RoleBasedGuard } from '../guards/role-based.guard';
import { NavDisplayModel } from '../models/nav-display.model';
import { UserRole } from '../models/user-roles.model';

export const staticRoutes: Routes = [
  {
    path: 'legaldisclosure',
    component: LegalDisclosureComponent,
    data: {
      navDisplay: [
        { name: 'Impressum', link: './' },
      ],
    },
  },
  {
    path: 'dataprotection',
    component: DataProtectionComponent,
    data: {
      navDisplay: [
        { name: 'Datenschutz', link: './' },
      ],
    },
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {
      navDisplay: [
        { name: 'FAQ', link: './' },
      ],
    },
  },
  {
    path: '',
    canActivateChild: [LoginGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'forms',
      },
      {
        path: 'forms',
        component: RouteIndexComponent,
        canActivate: [CateringRoleBasedRedirectGuard],
      },
      {
        path: 'forms/:formsCategory',
        component: RouteFormsComponent,
        data: {
          navDisplay: <NavDisplayModel[]>[
            { name: undefined, link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard, notRestricted],
      },
      {
        path: 'list',
        component: RouteFormOverviewComponent,
        data: {
          navDisplay: [
            { name: 'Vorgänge', link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard],
      },
      {
        path: 'catering',
        component: RouteCateringOverviewComponent,
        canActivate: [AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          navDisplay: [
            { name: 'Bewirtung', link: './' },
          ],
        },
      },
      {
        path: 'approvals',
        component: RouteApprovalOverviewComponent,
        data: {
          navDisplay: [
            { name: 'Freigabe', link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard],
      },
      {
        path: 'account',
        component: RouteAccountDataComponent,
        data: {
          navDisplay: [
            { name: 'Meine Daten', link: './' },
          ],
        },
      },
      {
        path: 'ocr/:formsCategory/:companyShortName/:formsIdentifier',
        component: RouteOcrComponent,
        data: {
          navDisplay: [
            { name: 'Kamerascan', link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard, notRestricted],
      },
      {
        path: 'ocr/:formsCategory/:formsIdentifier',
        component: RouteOcrComponent,
        data: {
          navDisplay: [
            { name: 'Kamerascan', link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard, notRestricted],
      },
      {
        path: 'config',
        component: RouteConfigComponent,
        canActivate: [GeneralAdminGuard],
        data: {
          navDisplay: [
            { name: 'Administration', link: './' },
          ],
        },
      },
      {
        path: 'template/deputy',
        component: RouteTemplateDeputyComponent,
        data: {
          navDisplay: [
            { name: 'Vorlagen', link: './' },
            { name: 'Vertretungen', link: './' },
          ],
        },
        canActivate: [CateringRoleBasedRedirectGuard, notRestricted],
      },
      {
        path: 'config/forms',
        component: RouteConfigFormsComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Formulare', link: './' },
          ],
        },
      },
      {
        path: 'config/forms/:formKey/:companyShort',
        component: RouteConfigFormComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Formulare', link: '/config/forms' },
            { name: 'Bearbeiten', link: './' },
          ],
        },
      },
      {
        path: 'config/forms-add/:formIdentifier/:companyShort',
        component: RouteConfigFormsAddComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Formulare', link: '/config/forms' },
            { name: 'Hinzufügen', link: './' },
          ],
        },
      },
      {
        path: 'config/company',
        component: RouteConfigCompanyComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Gesellschaftsverwaltung', link: './' },
          ],
        },
      },
      {
        path: 'config/company/:id',
        component: RouteConfigCompanyComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Gesellschaftsverwaltung', link: '/config/companies' },
            { name: 'Gesellschaft bearbeiten', link: './' },
          ],
        },
      },
      {
        path: 'config/companies',
        component: RouteConfigCompaniesComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Gesellschaftsverwaltung', link: './' },
          ],
        },
      },
      {
        path: 'config/taxrates',
        component: RouteConfigTaxRatesComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Steuersätze', link: './' },
          ],
        },
      },
      {
        path: 'config/ledgeraccounts',
        component: RouteConfigLedgerAccountListComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Sachkontoliste', link: './' },
          ],
        },
      },
      {
        path: 'config/salutations',
        component: RouteConfigSalutationsComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Anreden', link: './' },
          ],
        },
      },
      {
        path: 'config/deputies',
        component: RouteConfigDeputiesComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Vertreterregelung', link: './' },
          ],
        },
      },
      {
        path: 'config/systemMessage',
        component: RouteConfigSystemMessageComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Systemmeldung', link: './' },
          ],
        },
      },
      {
        path: 'config/rooms',
        component: RouteConfigLocationSelectionComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          allowedRoles: [UserRole.ADMIN, UserRole.CATERER_ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Raumlisten', link: './' },
          ],
        },
      },
      {
        path: 'config/rooms/:id',
        component: RouteConfigRoomComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          allowedRoles: [UserRole.ADMIN, UserRole.CATERER_ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Raumlisten', link: './config/rooms' },
            { name: 'Raumlisten bearbeiten', link: './' },
          ],
        },
      },
      {
        path: 'config/articles',
        component: RouteConfigLocationSelectionComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          allowedRoles: [UserRole.ADMIN, UserRole.CATERER_ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Artikellisten', link: './' },
          ],
        },
      },
      {
        path: 'config/articles/:id',
        component: RouteConfigArticleListComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          allowedRoles: [UserRole.ADMIN, UserRole.CATERER_ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Artikellisten', link: './config/articles' },
            { name: 'Artikellisten bearbeiten', link: './' },
          ],
        },
      },
      {
        path: 'config/catering/data',
        component: RouteConfigCateringOrderDataComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableCatering',
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Catering Bestelldaten', link: './' },
          ],
        },
      },
      {
        path: 'config/faq',
        component: RouteConfigFaqComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'FAQ pflegen', link: './' },
          ],
        },
      },
      {
        path: 'config/categoryInfo',
        component: RouteConfigCategoryInfoComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Kategorieinformation pflegen', link: './' },
          ],
        },
      },
      {
        path: 'config/massDataProcessing',
        component: RouteConfigMassDataProcessingComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableMassData',
          allowedRoles: [UserRole.MASSDATA],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Massendatenverarbeitung', link: './' },
          ],
        },
      },
      {
        path: 'config/servicecenter',
        component: RouteConfigServiceCenterComponent,
        canActivate: [RoleBasedGuard, AppConfigGuard],
        data: {
          configProp: 'enableNetRegion',
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Service Center pflegen', link: './' },
          ],
        },
      },
      {
        path: 'config/dashboard',
        component: RouteConfigDashboardComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.DASHBOARD],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Dashboard', link: './' },
          ],
        },
      },
      {
        path: 'config/queue',
        component: RouteConfigQueueComponent,
        canActivate: [RoleBasedGuard],
        data: {
          allowedRoles: [UserRole.ADMIN],
          navDisplay: [
            { name: 'Administration', link: '/config' },
            { name: 'Fehlgeschlagene Vorgänge', link: './' },
          ],
        },
      },
    ],
  },
];

