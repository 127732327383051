import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment/moment';
import { map } from 'rxjs/operators';
import { FormBaseState } from '../../forms-lib/models/base-state.model';
import { getReadonlyAfterWithDefault, getRestrictedAfterWithDefault } from '../ngrx/reducers/core.store';

export const notRestricted = () => {
  const store$ = inject(Store<FormBaseState>);
  return store$.select(getRestrictedAfterWithDefault(undefined)).pipe(
    map(restrictedAfter => restrictedAfter === undefined || moment().isBefore(restrictedAfter)
    )
  );
};

export const notReadonly = () => {
  const store$ = inject(Store<FormBaseState>);
  return store$.select(getReadonlyAfterWithDefault(undefined)).pipe(
    map(readonlyAfter => readonlyAfter === undefined || moment().isBefore(readonlyAfter)
    )
  );
};
